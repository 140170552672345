
import store from "@/store";
import { defineComponent } from 'vue';
import { Button, Searchbar, Table} from '@/ui/index';
import { MachineController } from '@/controller/';

export default defineComponent({
    name: "MachineList",
    components: { Button, Searchbar, Table },
    data() {
        return {    
            store,
            machineTableData: {
                thFields: [{title: this.$t("machine.number"), property: 'number', type: 'string' }, { title: this.$t("machine.title"), property: "title", type: "string", clickAction: 'openDetail'}],
                tbActions: [{ name: "openDetail", icon: store.getters.svgIcons.watch }, { name: "editMachine", icon: store.getters.svgIcons.pencil }, { name: "confirmDelete", icon: store.getters.svgIcons.remove }]
            },
            machines: [] as Array<any>,
            departments: [] as Array<any>,
            workareas: [] as Array<any>,
            searchInput: ""

        }
    },
    created(){
        this.handleGetMachines();
    },
    computed: {
        filteredMachines() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.machines.forEach((machine : any) => {
                    JSON.stringify(machine.number).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(machine);
                    !filtered.includes(machine) && JSON.stringify(machine.title).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(machine);
                })
            } else {
                filtered = this.machines;
            }
            return filtered;
        }
    },
    methods: {
        async handleGetMachines() {
            const res = await MachineController.fetchMachines();
            if(!res.error){
                this.machines = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },      
        handleTableActionClicked(action : string, item:any) {
            console.log("handleTable", action, item)
            switch(action) {
                case "openDetail":
                    this.handleOpenDetailView(item.uid);
                    break
                case "editMachine": 
                this.handleEdit(item.uid);
                    break
                case "confirmDelete":
                    this.handleConfirmDelete(item);
                    break
            }
        },
        handleShowCreateForm(){
            this.$router.push('/administration/machine/form/0');
        },
        handleOpenDetailView(uid : number) {
            this.$router.push("/administration/machine/detail/" + uid);
        },
        handleEdit(uid:number) {
            this.$router.push("/administration/machine/form/" + uid);
        },
        handleConfirmDelete(item : any) {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteMachineText', { name: item.title }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete(item.uid) },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async handleDelete(uid : number) {
            const res = await MachineController.deleteMachine(uid);
            if (!res.error) {
                this.machines = this.machines.filter(function(item : any) {
                   return item.uid != res.machine.uid;
                });
                this.$notify(this.$t("success.machineDeleted"), { position: "top" });
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
